<template>
<!-- 社区发表文章 -->
<div class='main'>
  <div class="head">
    <ul>
      <li v-for="(item,index) in headWenzi" :key="index">
        <i class="na-text" @click="this.$router.push(item.path)">{{item.name}}</i>
      </li>
      <li><a @click="this.$router.push('/coffee/user')">会员</a></li>
      <li><a @click="this.$router.push('/coffee')">咖啡社区</a></li>
      <li><a @click="this.$router.push('/live')">直播</a></li>
    </ul>
  </div>

  <div class="body">
    <div class="body_title">
      <img class="logo" src="../../static/ossImg/logo.png" alt="">
    </div>

    <div class="body_cont">
      <el-breadcrumb  separator-icon="ArrowRight">
        <el-breadcrumb-item to="/coffee">咖啡社区</el-breadcrumb-item>
        <el-breadcrumb-item>发表文章</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider />
      <div class="input_body">
        <div class="guanjianci">
          <span class="left">关键字：{{guanjianzi.name}}</span>
          <el-select v-model="GjzListValue" placeholder="请选择关键词">
            <el-option
              v-for="item in GjzList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="title">
          <span class="left">主题：</span>
          <el-input v-model="title" />
        </div>
        <div class="cont">
          <span class="left">正文：</span>
          <div id="editorshow">
            <!-- <editor-show @EditorFrom="(data)=>{editorDataList(data)}"></editor-show> -->
            <el-input
              v-model="textarea"
              :autosize="{ minRows:20, maxRows: 50 }"
              type="textarea"
              placeholder="输入内容"></el-input>
          </div>
        </div>
        <div class="cont">
          <span class="left">配图：</span>

          <div id="editorshow">
            <!-- <editor-show @EditorFrom="(data)=>{editorDataList(data)}"></editor-show> -->
            <el-upload
              :on-success="uploadSuccess"
              :before-upload="beforeUpload"
              :on-preview="handlePictureCardPreview"
              :action="upLoadUrl"
              :data="upLoadData"
              :on-remove="handleRemove"

              list-type="picture-card"
              :limit="6"
              accept="jpg/jpeg/png/gif"
            >
              <el-icon><plus /></el-icon>
            </el-upload>
          </div>
        </div>
      </div>
      <el-dialog v-model="dialogVisible">
        <img :src="dialogImageUrl" alt="" />
      </el-dialog>
      <div class="body_button">
        <el-button :loading="loading" @click="Publications">发表</el-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getCurrentInstance, reactive, ref } from 'vue-demi'
// import EditorShow from '../../components/EditorShow.vue'
import http from '../../api/http'
import { ElMessage } from 'element-plus'
// import { ArrowRight } from '@element-plus/icons'

// 论坛帖子保存
const save = (data) => {
  return http.post('/mall-portal/mall/post/save', data, true)
}
// 查询论坛一级
const queryForumFirstLevelClassification = () => {
  return http.get('/mall-portal/mall/post/queryForumFirstLevelClassification')
}
// 查询二级
const queryChildNodeByParentId = (data) => {
  return http.get(`/mall-portal/mall/post/queryChildNodeByParentId/${data}`)
}
const headWenzi = [
  { name: '首页', path: '/' },
  { name: '资讯', path: '/coffee/information' }
  // { name: '直播', path: '/live' },
  // { name: '咖啡社区', path: '/coffee' },
  // { name: '会员', path: '/coffee/user' }
]
export default {
  name: 'Publications',
  data () {
    return {
      title: ref(''),
      textarea: ref(''), // 文本域
      decriptList: ref(''),
      loading: ref(false),

      dialogVisible: ref(false),
      dialogImageUrl: ref(''),
      // 上传数据
      dir: null,
      fileList: [],
      upLoadUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com',
      upLoadData: {
        key: null,
        policy: null,
        OSSAccessKeyId: null,
        success_action_status: 200, // 让服务端返回200，否则默认返回204。
        signature: null
      },

      guanjianzi: reactive({}), // 关键字
      GjzList: ref([]), // 二级目录
      GjzListValue: '' // 二级选择
    }
  },
  computed: {
    showFileList: {
      get: function () {
        return this.value !== null && this.value !== '' && this.value !== undefined
      },
      set: function (newValue) {
      }
    }
  },
  // components: { EditorShow },
  methods: {
    async Publications () {
      this.loading = true
      console.log(this.fileList)
      const albumPics = this.fileList.map((item) => { return item.url }).join(',') // 图片拼接成字符串
      this.decriptList = this.textarea + '+-*/' + albumPics
      console.log(this.decriptList)
      if (!this.GjzListValue) {
        ElMessage.warning({
          message: '请选择分类！'
        })
        this.loading = false
        return
      }
      if (this.textarea !== '' && this.title !== '' && this.decriptList !== '') {
        const data = {
          antistop: this.GjzListValue,
          content: this.decriptList,
          title: this.title,
          postOrInformation: this.$route.query.postOrInformation
        }
        console.log(data)
        const res = await save(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '发表成功'
          })
          this.title = ''
          this.decriptList = ''
          this.loading = false
          this.$router.push('/coffee')
        }
      } else {
        this.loading = false
        ElMessage.error({
          message: '请完善信息！'
        })
        return ''
      }
    },
    uploadSuccess (res, file) {
      // console.log(res, file)

      this.showFileList = true
      this.fileList.pop()
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name // 拼接oss路径
      this.fileList.push({ name: file.name, url: url })
      // this.emitInput(this.fileList[0].url)
    },
    beforeUpload (file) { // 主图上传图片触发方法
      // console.log(file)
      // this.fileName = file.uid + file.name.substring(file.name.lastIndexOf('.'))
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension3 = testmsg === 'jpeg'
      const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 < 500 // 这里做文件大小限制
      if (!extension && !extension2 && !extension3 && !extension4) {
        ElMessage({
          message: '上传文件只能是 jpg、png、jpeg、gif格式!',
          type: 'warning'
        })
        // this.handleRemove(file)
        return
      }
      if (!isLt2M) {
        ElMessage({
          message: '上传文件大小不能超过 500k!',
          type: 'warning'
        })
        // this.handleRemove(file)
        return
      }
      const param = {
        prefix: 'coffee/'
      }
      return new Promise((resolve, reject) => {
        http.get('mall-admin/aliyun/oss/policy', param)
          .then(res => {
            // console.log(res)
            this.dir = res.data.data.dir
            this.upLoadData.key = `${res.data.data.dir}/${file.name}`
            this.upLoadData.policy = res.data.data.policy
            this.upLoadData.OSSAccessKeyId = res.data.data.accessKeyId
            this.upLoadData.signature = res.data.data.signature
            this.upLoadUrl = res.data.data.host
            this.fileList.push(file)
            // console.log(this.fileList)
            resolve(true)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    handlePictureCardPreview (file) { // 图片放大方法
      console.log(file)
      this.dialogImageUrl = file.url
      this.dialogVisible = true // 打开弹窗
    },
    handleRemove (file) { // 删除主图
      console.log(file)
      // console.log(fileList)
      this.fileList = this.fileList.filter((item) => {
        return item.name !== file.name
      }) // 过滤方法
      // console.log(this.fileList)
    },
    async initData () { // 获取一级分类
      const res = await queryForumFirstLevelClassification()
      // console.log(res)
      if (res.data.code === 200) {
        res.data.data.forEach(item => {
          // console.log(item)
          if (this.$route.query.postOrInformation === '0' && item.name === '咖啡社区') {
            this.guanjianzi = item
            this.initDataTwo(item.id)
          }
        })
      }
    },
    async initDataTwo (id) { // 获取二级分类
      const data = id
      const res = await queryChildNodeByParentId(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.GjzList = res.data.data
      }
    }
  },
  mounted () {
    this.initData()
  },
  setup (props, ctx) {
    const datacop = getCurrentInstance()
    const editorDataList = (data) => {
      console.log(data)
      datacop.data.decriptList = data
    }
    return {
      headWenzi,
      editorDataList
    }
  }
}
</script>

<style lang='scss' scoped>
//@import ''; 引入公共css类
.main{
  width: 100%;
  background: #f2efed;
  // height: 800px;
  font-family: Microsoft YaHei;
  color: #333333;
  .head{
    height: 30px;
    background: #FFFFFF;
    ul{
      width: 1200px;
      margin: 0 auto;
      display: block;
      line-height: 30px;
      font-size: 14px;
      li{
        float: left;
        display: flex;
        // width: 56px;
        height: 30px;
        margin-right: 20px;
        color: #333333;
        cursor: pointer;
        .na-text{
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
      }
      li:nth-last-child(3),li:nth-last-child(2),li:last-child{
        float: right;
        a{
          color: #666;
        }
      }
    }
  }
  .body{
    width: 1200px;
    background: #FFFFFF;
    margin: 0 auto;
    margin-top: 20px;
    .body_title{
      padding: 10px;
      display: flex;
      justify-content: space-between;
      img{
        width: 165px;
        height: 135px;
      }
    }
    .body_cont{
      padding: 10px;
      .input_body{
        .guanjianci{
          font-size: 20px;
          font-weight: bold;
          margin-bottom:30px;
          .left{
            margin-right: 20px;
          }
        }
        .title{
          font-size: 20px;
          font-weight: bold;
          .el-input{
            width: 870px;
          }
        }
        .cont{
          margin:30px 0;
          display: flex;
          align-items: center;
          span{
            font-size: 20px;
            font-weight: bold;
          }
          #editorshow{
            flex: 1;
          }
        }
      }
      .body_button{
        padding: 20px 50px;
        .el-button{
          width: 200px;
        }
      }
    }
  }
}
</style>
